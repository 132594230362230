import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { HTMLContent } from '../components/Content'
import { themeStyles, presets, colors, metrics } from '../utils/theme'
import Button from '../components/Button'
import { rhythm } from '../utils/typography'
import _ from 'lodash'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Footer, Header } from './lt-landing-page'


const styles = {
  facebookIcon: {
    backgroundColor: colors.blueGreen

  },
  container: {
    backgroundColor: colors.darkGreen,
    color: colors.white,
    padding: rhythm(1),
    height: rhythm(28),
    [presets.Tablet]: {
      height: rhythm(34),
      padding: `${rhythm(3)} ${rhythm(1)}`,
      width: '100%',
    },
    [presets.Desktop]: {
      ...themeStyles.textPadding,
    },
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    [presets.Tablet]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    [presets.Desktop]: {
      justifyContent: 'space-evenly',
    },
  },
  subContainer: {
    width: '100%',
    [presets.Tablet]: {
      marginRight: rhythm(1),
    },
    [presets.Desktop]: {
      width: rhythm(40),
    },
  },
  title: {
    marginTop: rhythm(2.5),
    fontSize: rhythm(1.2),
    textAlign: 'center',
    marginBottom: rhythm(2.5),
    [presets.Tablet]: {
      fontSize: rhythm(1.3),
      textAlign: 'left',
    },
    [presets.Desktop]: {
      ...themeStyles.textPadding,
    },
  },
  text: {
    fontSize: rhythm(1),
    [presets.Tablet]: {
      fontSize: rhythm(1.1),
    },
    [presets.Desktop]: {
      padding: `${rhythm(0.1)} ${metrics.defaultPadding}`,
    },
  },
  img: {
    visibility: 'hidden',
    [presets.Tablet]: {
      visibility: 'visible',
      marginTop: rhythm(2.5),
      minWidth: rhythm(10),
      height: rhythm(10),
    },
    [presets.Desktop]: {
      minWidth: rhythm(15),
      height: rhythm(15),
    },
  }, 
  buttonContainer: {
    marginTop: rhythm(3),
    [presets.Tablet]: {
      marginTop: rhythm(5),
    },
    [presets.Desktop]: {
      ...themeStyles.textPadding,
    },
  },
  button: {
    margin: `${rhythm(1)} auto 0 auto`,
    width: rhythm(10),
    fontSize: rhythm(1),
    [presets.Tablet]: {
      marginTop: rhythm(3),
      marginLeft: rhythm(0),
      width: rhythm(13),
      fontSize: rhythm(1.2),
    },
    [presets.Desktop]: {
      marginLeft: rhythm(3),
    },
  },
}

export const HiringPageTemplate = ({
  helmet,
  title,
  role,
  targetUrl,
  text,
  imageUrl
}) => {
  return (
    <div>
      <section className="section" css={styles.container}>
        <div css={styles.flexContainer}>
          <div css={styles.subContainer}>
            <h2 css={styles.title}>{title}</h2>
            {helmet || ''}
            {_.map(text, (t) => {
              return (<div key={t.subtext} css={styles.text}>{t.subtext}</div>)
            })}
            <div style={styles.buttonContainer}>
              <Button
                href={targetUrl}
                analyticsTargetName={`Apply Now - ${role}`}
                text='Apply Now'
                style={styles.button}
                size='lg'
              />
            </div>
          </div>
          <GatsbyImage
            css={styles.img}
            image={_.get(imageUrl, 'childImageSharp.gatsbyImageData')}
            alt='hiring'
          />
        </div>
      </section>
    </div>
  )
}

HiringPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string,
  role: PropTypes.string,
  targetUrl: PropTypes.string,
  imageUrl: PropTypes.object,
  text: PropTypes.arrayOf(
    PropTypes.shape({
      subtext: PropTypes.string
    })
    ),
}

const HiringPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <div>
      <Header showPartnerLogo={true} partnerLogo={'facebookNavBar'}/>
      <HiringPageTemplate
        content={post.htmlAst}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        role={post.frontmatter.role}
        targetUrl={post.frontmatter.targetUrl}
        imageUrl={post.frontmatter.imageUrl}
        text={post.frontmatter.text}
        helmet={
          <Helmet titleTemplate="%s | Hiring">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.title}`}
            />
          </Helmet>
        }
      />
      <Footer disclaimerText='' hideTrustPilot={true}/>

    </div>
  )
}

HiringPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default HiringPage

export const pageQuery = graphql`
  query HinringPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        role
        targetUrl 
        imageUrl {
          childImageSharp {
            gatsbyImageData(width: 400, quality: 100, layout: CONSTRAINED)
          }
        }
        text {
          subtext
        }
      }
    }
  }
`
